<template>
	<div>
		<Header title='礼品领取'></Header>
		<div class="addressTop">
			<img src="../../assets/image/luckDraw/address.png" style="width: 43px;height: 44px;">
			<div style="margin-left: 16px;">
				<div class="flex align-center">
					<div style="width: #434444;font-size: 14px;font-weight: bold;">{{info.userName}}</div>
					<div style="color: #8E8E8E;font-size: 14px;margin-left: 12px;">{{info.userPhone}}</div>
				</div>
				<div style="margin-top: 6px;color: #434444;font-size: 12px;">{{info.area}}{{info.address}}</div>
			</div>
		</div>
		<div class="addGift">
			<!-- <div class="giftTop flex align-center">
				<img src="../../assets/image/luckDraw/ruiLogo.png" style="width: 22px;height: 22px;">
				<div style="color: #434444;font-size: 14px;font-weight: bold;margin-left: 4px;">瑞士旅游局</div>
			</div> -->
			<div class="giftCon flex align-center">
				<img :src="host+giftInfo.thumb" style="width: 100px;height: 82px;">
				<div style="width: 100%;margin-left: 14px;">
					<div class="commonWz">
						{{giftInfo.prize_name}}
					</div>
					<div class="flex space-between align-center" style="margin-top: 10px;">
						<div class="commonWz">数量</div>
						<div class="commonWz" style="font-weight: normal;">1</div>
					</div>
				</div>
			</div>
			<div class="giftBtm flex align-center space-between">
				<div class="flex align-center">
					<div class="commonWz">配送方式</div>
					<div style="color: #A4A4A4;font-size: 12px;margin-left: 16px;">普通配送</div>
				</div>
				<div class="commonWz">免邮费</div>
			</div>
			<!-- <div class="giftBtm flex align-center space-between">
				<div class="commonWz">物流单号</div>
				<div class="commonWz">顺丰 4323358617799926</div>
			</div> -->
		</div>
		<div @click="receive" class="btn" v-if="type==''">立即领取</div>
	</div>
</template>

<script>
	import {
		userAddress
	} from '../../api/mine.js'
	import {
		prizeDetail,
		receive
	} from '../../api/home.js'
	import {
		host
	} from '../../utils/host.js'
	import Header from '@/components/header.vue'
	export default {
		name: 'giftReceive',
		data() {
			return {
				host: host,
				wbl: require('../../assets/image/luckDraw/xcq.png'),
				token: window.localStorage.getItem('token'),
				info: {},
				id: '',
				giftInfo: {},
				type: ''
			}
		},
		components: {
			Header
		},
		created() {
			this.id = this.$route.query.id
			if (this.$route.query.type) {
				this.type = this.$route.query.type
			}
			this.getAddress()
			this.GiftDetail()
		},
		methods: {
			GiftDetail() {
				let data = new FormData()
				data.append('token', this.token)
				data.append('id', this.id)
				prizeDetail(data).then(res => {
					console.log('奖品详情', res)
					this.giftInfo = res.data
				})
			},
			receive() {
				let data = new FormData()
				data.append('token', this.token)
				data.append('id', this.id)
				receive(data).then(res => {
					console.log('领取奖品', res)
					this.$toast.success('领取成功');
					setTimeout(() => {
						this.$router.push({
							path: '/mine'
						})
					})
				})
			},
			getAddress() {
				let data = new FormData()
				data.append('token', this.token)
				userAddress(data).then(res => {
					console.log('领奖地址', res)
					this.info = res.data
				})
			},
		}
	}
</script>

<style scoped>
	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.addressTop {
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		margin: 13px 16px 0 16px;
		padding: 0 26px 0 14px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
	}

	.addGift {
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		margin: 12px 16px 0 16px;
		padding: 16px 14px;
		box-sizing: border-box;
	}

	.giftCon {
		margin-top: 11px;
	}

	.giftBtm {
		margin-top: 21px;
	}

	.commonWz {
		color: #434444;
		font-size: 12px;
		font-weight: bold;
	}

	.btn {
		background: #DD1C25;
		width: 7.4rem;
		height: 40px;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 20px;
		position: fixed;
		bottom: 4%;
		left: 13%;
		letter-spacing: 2px;
		color: #fff;
		font-weight: bold;
		font-size: 16px;
		line-height: 40px;
		text-align: center;
	}
</style>
